import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { CustomButton, CustomTable, Text } from "../../shared/uiComponents";
import { useCheckPermission } from "../../shared/Helpers/hooks";
import { Container, Header } from "../../components/StyledComponents";
import SearchBar from "../../components/SearchBar";
import { AdminTypes } from "../../components/Menu";
import { PERMISSIONS } from "../../App/constants";
import { Headers, TableBody } from "./tableInfo";
import MobileView from "./mobileView";
import MobileViewBCBA from "./mobileViewBCBA";

import { DispatchProperties, useSelector } from "../../redux/store";
import { getClients } from "../../redux/State/clientSlice/clientSlice";
import { getUserClients } from "../../redux/State/clientSlice/userClientSlice";

const Clients = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const { permissionGranted } = useCheckPermission(PERMISSIONS.CLIENT.CREATE);

  const userId = useSelector((state) => state.account.user.id);
  const role = useSelector((state) => state.account.role);
  const clientsData = useSelector((state) => state.client.clients);
  const userClients = useSelector((state) => state.userClient.userClients);
  const loading = useSelector((state) => state.client.loading);
  const loadingUserClients = useSelector(
    (state) => state.userClient.loadingGetUserClients
  );

  const addClientHandler = () => navigate("/clients/new");

  useEffect(() => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const searchString = params.get("search") || "";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const status = params.get("status");

    if (!userId || !role) return;

    if (role.section.id === AdminTypes.admin) {
      dispatch(
        getClients({
          page,
          pageSize,
          searchString,
          orderBy,
          direction,
          status,
        })
      );
      return;
    }

    dispatch(
      getUserClients({
        userId,
        page,
        pageSize,
        searchString,
        orderBy,
        direction,
        status,
      })
    );
  }, [params, userId, dispatch, role]);

  return (
    <Container>
      <Header>
        <Text title={"Clients"} size="mediumBold" />
        <Header>
          {permissionGranted && (
            <CustomButton
              title={"Add client"}
              className={"marginRight16"}
              onClick={addClientHandler}
            />
          )}
          <SearchBar />
        </Header>
      </Header>
      <CustomTable
        headers={Headers}
        data={role?.section.id === AdminTypes.admin ? clientsData : userClients}
        TableBody={TableBody}
        loading={loading || loadingUserClients}
      />
      {role?.section.id === AdminTypes.admin ? (
        <MobileView {...clientsData} />
      ) : (
        <MobileViewBCBA {...userClients} />
      )}
    </Container>
  );
};

export default Clients;
