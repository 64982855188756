import dayjs from "dayjs";

import { Text } from "../../shared/uiComponents";
import { red300 } from "../../shared/Helpers/colors";

const ExpireInfo = ({ endDate }: { endDate: string }) =>
  dayjs(endDate).diff(dayjs(), "days") > -1 ? (
    dayjs(endDate).isSame(dayjs(), "days") ? (
      <Text title={`Expires today`} size="tinyBold" />
    ) : (
      <Text
        title={`Expires in ${dayjs(endDate).diff(dayjs(), "days") + 1} day${
          dayjs(endDate).diff(dayjs(), "days") > 1 ? "s" : ""
        }`}
        size="tinyBold"
      />
    )
  ) : (
    <Text title={"Expired"} textColor={red300} size="tinyBold" />
  );

export default ExpireInfo;
