import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";

import { Picker, Text, SearchSelect } from "../../../shared/uiComponents";
import { SelectDataProperties } from "../../../shared/uiComponents/Dropdown/searchSelect";
import { dateNormalizer } from "../../../shared/Helpers/functions";
import { red300 } from "../../../shared/Helpers/colors";
import { useCheckPermission } from "../../../shared/Helpers/hooks";
import { PERMISSIONS } from "../../../App/constants";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  getUserClients,
  getUserBTs,
} from "../../../redux/State/clientSlice/userClientSlice";
import {
  setClientIdFilters,
  setEndDate,
  setStartDate,
  setUserIdFilters,
} from "../../../redux/State/clientSlice/billingSlice";

const Filters = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const CLIENT = useCheckPermission(PERMISSIONS.CLIENT.READ);

  const todayDayjs = dayjs();
  const pastDateMonth = dayjs().startOf("month");

  const accountId = useSelector((state) => state.account.user.id);
  const billingFilter = useSelector((state) => state.billing.billingFilter);
  const bts = useSelector((state) => state.userClient.userBTs);
  const loadingClients = useSelector((state) => state.client.loading);

  const [startDateValue, setStartDateValue] = useState<Dayjs>(pastDateMonth);
  const [endDateValue, setEndDateValue] = useState<Dayjs>(todayDayjs);
  const [clientId, setClientId] = useState<string>("");
  const [userId, setUserId] = useState<string>("");

  const [searchStringTherapists, setSearchStringTherapists] =
    useState<string>("");
  const [searchStringClients, setSearchStringClients] = useState<string>("");
  const [btList, setBTList] = useState<Array<SelectDataProperties>>([]);
  const [pageSize, setPageSize] = useState<number>(8);
  const [clientsData, setclientsData] = useState<Array<SelectDataProperties>>(
    []
  );

  const choosenProvider = btList.find((x) => x.id === billingFilter.userId);
  const choosenClient = clientsData.find((x) => x.id === clientId);

  const onChangeStartDate = (value: Dayjs | null) => {
    if (!value) return;
    setStartDateValue(value);
  };

  const onChangeEndDate = (value: Dayjs | null) => {
    if (!value) return;
    setEndDateValue(value);
  };

  const onTherapistChoose = (value: string) => {
    setUserId(value);
  };

  const onClientChoose = (value: string) => {
    setClientId(value);
  };

  useEffect(() => {
    if (!!clientId) return;
    setSearchStringClients("");
  }, [clientId]);

  useEffect(() => {
    const start = dateNormalizer(startDateValue);
    const end = dateNormalizer(endDateValue);
    dispatch(setUserIdFilters(userId));
    dispatch(setClientIdFilters(clientId));
    dispatch(setStartDate(start));
    dispatch(setEndDate(end));
  }, [startDateValue, endDateValue, clientId, userId, dispatch]);

  useEffect(() => {
    if (!bts || !bts.length) return;
    if (!searchStringTherapists.length) {
      const list = bts.map((x) => ({ id: x.id, label: x.fullName }));
      setBTList(list);
      return;
    }
    const list = bts
      .filter((x) =>
        x.fullName.toLowerCase().includes(searchStringTherapists.toLowerCase())
      )
      .map((x) => ({ id: x.id, label: x.fullName }));
    setBTList(list);
  }, [bts, searchStringTherapists]);

  useEffect(() => {
    if (!pageSize || !accountId || !CLIENT.permissionGranted) return;

    const timeout = setTimeout(() => {
      dispatch(
        getUserClients({
          userId: !!billingFilter.userId ? billingFilter.userId : accountId,
          page: "1",
          pageSize: `${pageSize}`,
          searchString: searchStringClients,
        })
      )
        .then(unwrapResult)
        .then((response) => {
          if (!response.query) return;
          const data = response.query.map((client) => ({
            id: client.id,
            label: client.fullName,
          }));
          setclientsData(data);
        });
    }, 1000);
    return () => clearTimeout(timeout);
  }, [
    dispatch,
    pageSize,
    billingFilter.userId,
    accountId,
    searchStringClients,
    CLIENT.permissionGranted,
  ]);

  useEffect(() => {
    if (!accountId) return;
    dispatch(getUserBTs(accountId));
  }, [accountId, dispatch]);

  return (
    <>
      <div style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
        <div>
          <SearchSelect
            data={btList}
            className={"marginRight16"}
            label="Find Provider:"
            setSearchString={setSearchStringTherapists}
            searchString={searchStringTherapists}
            loading={false}
            setValue={onTherapistChoose}
          />
        </div>
        {CLIENT.permissionGranted && (
          <div>
            <SearchSelect
              label={`Find ${
                !!choosenProvider ? `${choosenProvider.label}'s` : ""
              } Client:`}
              data={clientsData}
              className={"marginRight16"}
              setSearchString={setSearchStringClients}
              searchString={searchStringClients}
              setValue={onClientChoose}
              loading={loadingClients}
              loadMore={{
                activate: true,
                setSize: setPageSize,
              }}
            />
          </div>
        )}
        <Picker.CustomDate
          label="Start Date:"
          value={startDateValue}
          onChange={onChangeStartDate}
        />
        <Picker.CustomDate
          label="End Date:"
          value={endDateValue}
          onChange={onChangeEndDate}
        />
      </div>
      <Text
        textColor={red300}
        size="smallBold"
        title={`Displaying ${
          !!clientId
            ? `${choosenClient?.label}'s`
            : !!billingFilter.userId
            ? `${choosenProvider?.label}'s`
            : "all"
        } notes`}
      />
    </>
  );
};

export default Filters;
