import { SaveUserDataProperties, UserProperties } from "../../redux/API/userAPIProperties";

export enum ACTIONS {
    setState = "setState",
    setId = "setId",
    setFirstName = "setFirstName",
    setLastName = "setLastName",
    setFullName = "setFullName",
    setEmail = "setEmail",
    setPhoneNumber = "setPhoneNumber",
    setDateOfBirth = "setDateOfBirth",
    setAddress = "setAddress",
    setRoleId = 'setRoleId',
    setAuthorizationType = "setAuthorizationType",
    setInsurances = "setInsurances",
};

export const initialState: SaveUserDataProperties = {
    firstName: "",
    lastName: "",
    fullName: "",
    phoneNumber: "",
    address: "",
    email: "",
    roleId: "",
    authorizationType: null,
    insurances: [],
};

export interface ActionProperties {
    type: keyof typeof ACTIONS,
    payload: any,
};

export const adminReducer = (state: UserProperties, action: ActionProperties) => {
    const { type, payload } = action;

    switch (type) {
        case ACTIONS.setState:
            return {
                ...state,
                ...payload,
            }
        case ACTIONS.setId:
            return {
                ...state,
                id: payload,
            };
        case ACTIONS.setFirstName:
            return {
                ...state,
                firstName: payload,
            };
        case ACTIONS.setFullName:
            return {
                ...state,
                fullName: payload,
            };
        case ACTIONS.setLastName:
            return {
                ...state,
                lastName: payload,
            };
        case ACTIONS.setEmail:
            return {
                ...state,
                email: payload,
            };
        case ACTIONS.setPhoneNumber:
            return {
                ...state,
                phoneNumber: payload,
            };
        case ACTIONS.setAddress:
            return {
                ...state,
                address: payload,
            };
        case ACTIONS.setDateOfBirth:
            return {
                ...state,
                dateOfBirthday: payload,
            };
        case ACTIONS.setRoleId:
            return {
                ...state,
                roleId: payload,
            }
        case ACTIONS.setAuthorizationType:
            return {
                ...state,
                authorizationType: payload,
            }
        case ACTIONS.setInsurances:
            return {
                ...state,
                insurances: payload,
            }
        default:
            return state;
    }
};