import { ChangeEvent, Dispatch, useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { SelectChangeEvent } from "@mui/material/Select";
import dayjs from "dayjs";

import {
  CustomInput,
  CustomSelect,
  Text,
  MultipleSelect,
} from "../../../shared/uiComponents";
import { validateName } from "../../../shared/Helpers/functions";
import { useAssessmentOptions } from "../hook";
import {
  ACTIONS,
  ActionProperties,
  AssessmentErrorReducer,
  ERRORS,
  initialAssessmentErrorState,
} from "./assessmentReducer";
import { SessionBehaviorsInfo, SessionTargetsInfo, TimeInfo } from "../helpers";
import { SaveAssessmentTypeProperties } from "../SoapNoteProperties/assessmentTypeProperties";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { getLocations } from "../../../redux/State/clientSlice/soapNoteSlice";

const AssessmentData = ({
  data,
  setData,
  disabled = false,
}: {
  data: SaveAssessmentTypeProperties;
  setData: Dispatch<ActionProperties>;
  disabled?: boolean;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const today = new Date();
  const todayString = dayjs(today).format("MM/DD/YYYY");

  const [errors, setErrors] = useReducer(
    AssessmentErrorReducer,
    initialAssessmentErrorState
  );

  const { assessmentToolsList, peopleAttendanceList, treatmentPlanList } =
    useAssessmentOptions();

  const locationList = useSelector((state) => state.soapNote.locations);
  const { client, user } = useSelector((state) => state.session.sessionInfo);
  const errorList = useSelector((state) => state.soapNote.error.errors);

  useEffect(() => {
    if (!errorList) return;
    const list = Object.keys(errorList);
    list.forEach((id) => setErrors({ type: ERRORS[id], payload: true }));
  }, [errorList]);

  useEffect(() => {
    if (!!locationList || !client || !client.id) return;
    dispatch(getLocations(client.id));
  }, [dispatch, locationList, client]);

  const selectAssessmentToolHandler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ payload: value, type: ACTIONS.setAssessmentTools });
    if (!errors.AssessmentToolResponses) return;
    setErrors({ payload: false, type: ERRORS.AssessmentToolResponses });
  };

  const selectTreatmentPlanHAndler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ payload: value, type: ACTIONS.setTreatmentPlans });
    if (!errors.TreatmentPlanResponses) return;
    setErrors({ payload: false, type: ERRORS.TreatmentPlanResponses });
  };

  const selectPeopleAttendanceHandler = (
    event: SelectChangeEvent<string[]>
  ) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ payload: value, type: ACTIONS.setParticipants });
    if (!errors.ParticipantResponses) return;
    setErrors({ payload: false, type: ERRORS.ParticipantResponses });
  };

  const selectLocationHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setLocationId });
    if (!errors.LocationId) return;
    setErrors({ payload: false, type: ERRORS.LocationId });
  };

  const sessionSummaryHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setSessionSummary });
    if (!errors.SessionSummary) return;
    setErrors({ payload: false, type: ERRORS.SessionSummary });
  };

  const dataCollectedHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setDataCollected });
    if (!errors.DataCollected) return;
    setErrors({ payload: false, type: ERRORS.DataCollected });
  };

  const goalsCreatedHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setGoalsCreated });
    if (!errors.GoalsCreated) return;
    setErrors({ payload: false, type: ERRORS.GoalsCreated });
  };

  const signatureHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setData({ payload: value.toUpperCase(), type: ACTIONS.setSignature });
    if (!errors.Signature) return;
    setErrors({ payload: false, type: ERRORS.Signature });
  };

  const validateSignatureHandler = () => {
    if (validateName(data.signature)) return;
    setErrors({ payload: true, type: ERRORS.Signature });
  };

  return (
    <div>
      <TimeInfo disabled={disabled} />
      <CustomSelect
        label="Location:"
        data={locationList}
        value={!!data.locationId ? data.locationId : ""}
        setValue={selectLocationHandler}
        className={"marginBottom16"}
        disabled={disabled}
        error={errors.LocationId}
      />
      <MultipleSelect
        label="People in attendance:"
        data={peopleAttendanceList}
        initialValue={data.participantResponses}
        setValue={selectPeopleAttendanceHandler}
        className={"marginBottom16"}
        error={errors.ParticipantResponses}
        disabled={disabled}
      />
      <MultipleSelect
        label="Assessment tools used: "
        data={assessmentToolsList}
        initialValue={data.assessmentToolResponses}
        setValue={selectAssessmentToolHandler}
        className="marginBottom16"
        error={errors.AssessmentToolResponses}
        disabled={disabled}
      />
      <MultipleSelect
        label="Treatment Plan:"
        data={treatmentPlanList}
        initialValue={data.treatmentPlanResponses}
        setValue={selectTreatmentPlanHAndler}
        className="marginBottom16"
        error={errors.TreatmentPlanResponses}
        disabled={disabled}
      />
      <CustomInput
        label="Data collected:"
        value={data.dataCollected}
        setValue={dataCollectedHandler}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        className="marginBottom16"
        error={errors.DataCollected}
        disabled={disabled}
      />
      <CustomInput
        label="Goals created:"
        value={data.goalsCreated}
        setValue={goalsCreatedHandler}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        className="marginBottom16"
        error={errors.GoalsCreated}
        disabled={disabled}
      />
      <Text
        title="Session Details"
        size="smallBold"
        className="marginBottom16 title"
      />
      <SessionTargetsInfo />
      <SessionBehaviorsInfo />
      <CustomInput
        label="Session Summary: "
        value={data.sessionSummary}
        setValue={sessionSummaryHandler}
        className="marginBottom8"
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        error={errors.SessionSummary}
        disabled={disabled}
      />
      <div style={{ width: "250px" }}>
        <CustomInput
          label="Printed Name:"
          value={user.fullName.toUpperCase()}
          setValue={() => {}}
          disabled={true}
          className="marginBottom8 signature"
        />
        <CustomInput
          label="Signature:"
          value={data.signature}
          setValue={signatureHandler}
          className="marginBottom8 signature"
          error={errors.Signature}
          disabled={disabled}
          onBlur={validateSignatureHandler}
        />
      </div>
      <div>
        <Text title="Date of signature: " size="smallBold" />
        <Text title={todayString} />
      </div>
    </div>
  );
};

export default AssessmentData;
