import { SaveTreatmentPlanningTypeProperties } from "../SoapNoteProperties/treatmentPlanningTypeProperties";

export enum ACTIONS {
    setState = "setState",
    setDate = "setDate",
    setEndTime = "setEndTime",
    setStartTime = "setStartTime",
    setLocationId = "setLocationId",
    setSessionId = "setSessionId",
    setSignature = "setSignature",
    setPrintedName = "setPrintedName",
    setSubmit = "setSubmit",
    setParticipants = "setParticipants",
    setMasteredGoalsAndObjectives = "setMasteredGoalsAndObjectives",
    setNewGoalsAndObjectives = "setNewGoalsAndObjectives",
    setTreatmentUpdateResponses = "setTreatmentUpdateResponses",
    setAnalysisOfGoals = "setAnalysisOfGoals",
    setGoalUpdates = "setGoalUpdates",
    setCoordinationAndMaterialsPrepped = "setCoordinationAndMaterialsPrepped",
    setDescriptionOfSession = "setDescriptionOfSession",
}

export interface ActionProperties {
    type: keyof typeof ACTIONS,
    payload: any,
};

export const initialTreatmentPlaningState: SaveTreatmentPlanningTypeProperties = {
    date: '',
    startTime: "",
    endTime: '',
    locationId: null,
    printedName: '',
    sessionId: "",
    signature: '',
    submit: false,
    participantResponses: [],
    analysisOfGoals: "",
    coordinationAndMaterialsPrepped: "",
    descriptionOfSession: "",
    goalUpdates: "",
    masteredGoalsAndObjectives: "",
    newGoalsAndObjectives: "",
    treatmentUpdateResponses: [],
    forcedOperation: false,
}

export const treatmentPlaningReducer = (
    state: SaveTreatmentPlanningTypeProperties,
    action: ActionProperties,
): SaveTreatmentPlanningTypeProperties => {

    const { type, payload } = action;

    switch (type) {
        case ACTIONS.setState:
            return {
                ...state,
                ...payload,
            }
        case ACTIONS.setParticipants:
            return {
                ...state,
                participantResponses: payload,
            }
        case ACTIONS.setLocationId:
            return {
                ...state,
                locationId: payload,
            }
        case ACTIONS.setMasteredGoalsAndObjectives:
            return {
                ...state,
                masteredGoalsAndObjectives: payload,
            }
        case ACTIONS.setNewGoalsAndObjectives:
            return {
                ...state,
                newGoalsAndObjectives: payload,
            }
        case ACTIONS.setTreatmentUpdateResponses:
            return {
                ...state,
                treatmentUpdateResponses: payload,
            }
        case ACTIONS.setAnalysisOfGoals:
            return {
                ...state,
                analysisOfGoals: payload,
            }
        case ACTIONS.setGoalUpdates:
            return {
                ...state,
                goalUpdates: payload,
            }
        case ACTIONS.setCoordinationAndMaterialsPrepped:
            return {
                ...state,
                coordinationAndMaterialsPrepped: payload,
            }
        case ACTIONS.setDescriptionOfSession:
            return {
                ...state,
                descriptionOfSession: payload,
            }
        case ACTIONS.setSignature:
            return {
                ...state,
                signature: payload,
            }
        default:
            return state;
    }
}

export enum ERRORS {
    LocationId = "LocationId",
    SessionId = "SessionId",
    Signature = "Signature",
    ParticipantResponses = "ParticipantResponses",
    MasteredGoalsAndObjectives = "MasteredGoalsAndObjectives",
    NewGoalsAndObjectives = "NewGoalsAndObjectives",
    TreatmentUpdateResponses = "TreatmentUpdateResponses",
    AnalysisOfGoals = "AnalysisOfGoals",
    GoalUpdates = "GoalUpdates",
    CoordinationAndMaterialsPrepped = "CoordinationAndMaterialsPrepped",
    DescriptionOfSession = "DescriptionOfSession",
}

interface TreatmentPlaningErrorStateProperties {
    LocationId: boolean,
    SessionId: boolean,
    Signature: boolean,
    ParticipantResponses: boolean,
    MasteredGoalsAndObjectives: boolean,
    NewGoalsAndObjectives: boolean,
    TreatmentUpdateResponses: boolean,
    AnalysisOfGoals: boolean,
    GoalUpdates: boolean,
    CoordinationAndMaterialsPrepped: boolean,
    DescriptionOfSession: boolean,
}

export const initialTreatmentPlaningErrorState: TreatmentPlaningErrorStateProperties = {
    LocationId: false,
    SessionId: false,
    Signature: false,
    ParticipantResponses: false,
    MasteredGoalsAndObjectives: false,
    NewGoalsAndObjectives: false,
    TreatmentUpdateResponses: false,
    AnalysisOfGoals: false,
    GoalUpdates: false,
    CoordinationAndMaterialsPrepped: false,
    DescriptionOfSession: false,
}

interface ErrorActionProperties {
    type: keyof typeof ERRORS,
    payload: any,
}

export const treatmentPlaningErrorReducer = (state: TreatmentPlaningErrorStateProperties, action: ErrorActionProperties) => {
    const { payload, type } = action;
    switch (type) {
        case ERRORS[type]:
            return {
                ...state,
                [type]: payload,
            }
        default:
            return state
    }
}