import { useReducer, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

import { ACTIONS, clientReducer } from "./clientReducer";
import { initialUpdateClientState } from "./clientInitialStates";
import ClientForm from "./clientForm";
import { errorNormalizer } from "../../../shared/Helpers/functions";

import { DispatchProperties } from "../../../redux/store";
import {
  getClient,
  updateClient,
} from "../../../redux/State/clientSlice/clientSlice";
import { UpdateClientInfoProperties } from "../../../redux/API/ClientAPIHelpers/clientProperties";

const UpdateClient = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();
  const { clientId } = useParams();

  const [clientInfo, setClientInfo] = useReducer(
    clientReducer,
    initialUpdateClientState
  );

  useEffect(() => {
    if (!clientId) return;
    dispatch(getClient(clientId))
      .then(unwrapResult)
      .then((response) => {
        const insuranceId = response.insurance?.id;
        const status = response.status.id;
        const diagnosisCodeId = response.diagnosisCode.id;
        const payload: UpdateClientInfoProperties = {
          ...response,
          insuranceId,
          status,
          diagnosisCodeId,
        };

        setClientInfo({ type: ACTIONS.setState, payload });
      })
      .catch(errorNormalizer);
  }, [clientId, dispatch]);

  const updateClientHandler = () => {
    dispatch(updateClient(clientInfo))
      .then(unwrapResult)
      .then(() => navigate(-1))
      .catch(errorNormalizer);
  };

  return (
    <ClientForm
      clientInfo={clientInfo}
      setClientInfo={setClientInfo}
      saveClientHandler={updateClientHandler}
    />
  );
};

export default UpdateClient;
