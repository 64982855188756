import { v4 as randomUUID } from "uuid";

export interface MenuProperties {
  id: string;
  title: string;
  url: string;
  permission: string | null;
}

export const PERMISSIONS = {
  SUPERADMIN: "all",
  CLIENT: {
    READ: "client.read",
    CREATE: "client.create",
    EDIT: "client.edit",
    DELETE: "client.delete",
  },
  USER: {
    READ: "user.read",
    CREATE: "user.create",
    EDIT: "user.edit",
    DELETE: "user.delete",
  },
  SOAPNOTE: {
    READ: "soapnote.read",
    UNLOCK: "admin.soapnote.unlock",
    PDF: "soapnote.pdf",
    CREATE: "soapnote.create",
    EDIT: "soapnote.edit",
  },
  REPORT: {
    UTILIZATION: "admin.report.utilization.download",
    GRAPH: "report.graph.download",
    SOAPNOTE: "admin.report.soapnote.download",
    NONCREDENTIALED: "admin.report.noncredentialed.detailed.download",
    NONCREDENTIALED_TOTAL: "admin.report.noncredentialed.total.download",
    MBH: "admin.report.mbh.detailed.download",
  }

}

const ADMIN: Array<MenuProperties> = [
  { id: randomUUID(), title: "Dashboard", url: "/dashboard", permission: null },
  { id: randomUUID(), title: 'Calendar', url: '/calendar', permission: PERMISSIONS.SOAPNOTE.CREATE },
  { id: randomUUID(), title: "Clients", url: "/clients", permission: PERMISSIONS.CLIENT.READ },
  { id: randomUUID(), title: "All Users", url: "/user", permission: PERMISSIONS.USER.READ },
  { id: randomUUID(), title: "Admin", url: "/admin", permission: PERMISSIONS.USER.READ },
  { id: randomUUID(), title: "BCBA", url: "/bcba", permission: PERMISSIONS.USER.READ },
  { id: randomUUID(), title: "Behavior Technician", url: "/bt", permission: PERMISSIONS.USER.READ },
  { id: randomUUID(), title: "SOAP Notes", url: "/soap-notes", permission: PERMISSIONS.SOAPNOTE.READ },
  { id: randomUUID(), title: "Permissions", url: "/permissions", permission: PERMISSIONS.SUPERADMIN },
  { id: randomUUID(), title: "Reports", url: "/reports/mbh", permission: 'report' },
];

const BCBA: Array<MenuProperties> = [
  { id: randomUUID(), title: 'Dashboard', url: '/dashboard', permission: null },
  { id: randomUUID(), title: 'Calendar', url: '/calendar', permission: PERMISSIONS.SOAPNOTE.CREATE },
  { id: randomUUID(), title: 'Clients', url: '/clients', permission: PERMISSIONS.CLIENT.READ },
  { id: randomUUID(), title: 'SOAP Notes', url: '/soap-notes', permission: PERMISSIONS.SOAPNOTE.READ },
  { id: randomUUID(), title: 'Reports', url: '/reports', permission: PERMISSIONS.REPORT.GRAPH },
]

const BT: Array<MenuProperties> = [
  { id: randomUUID(), title: 'Dashboard', url: '/dashboard', permission: null },
  { id: randomUUID(), title: 'Calendar', url: '/calendar', permission: PERMISSIONS.SOAPNOTE.CREATE },
  { id: randomUUID(), title: 'Clients', url: '/clients', permission: PERMISSIONS.CLIENT.READ },
  { id: randomUUID(), title: 'SOAP Notes', url: '/soap-notes', permission: PERMISSIONS.SOAPNOTE.READ },
]

export const MENU = {
  ADMIN,
  BCBA,
  BT,
}
