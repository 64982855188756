import { useEffect, useReducer } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";

import BehaviorTreatmentModificationData from "./behaviorTreatmentModificationData";
import {
  ACTIONS,
  behaviorTreatmentModificationReducer,
  initialBehaviorTreatmentModificationState,
} from "./behaviorTreatmentModificationReducer";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import {
  hasAnyData,
  removeSavedSoapNoteData,
  SaveSoapNote,
  SOAP_NOTES,
  SoapNoteButtons,
} from "../helpers";
import { SaveBehaviorTreatmentModificationTypeProperties } from "../SoapNoteProperties/behaviorTreatmentModificationTypeProperties";
import { useResetCalendarData } from "../../Calendar/Helpers/hooks";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { saveBehaviorTreatmentModification } from "../../../redux/State/clientSlice/soapNoteSlice";

const CreateSoapNote = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { resetData } = useResetCalendarData();

  const paramsStatus = params.get("soapNoteStatus");
  const soapNoteStatus = !!paramsStatus ? parseInt(paramsStatus) : 0;

  const [data, setData] = useReducer(
    behaviorTreatmentModificationReducer,
    initialBehaviorTreatmentModificationState
  );

  const sessionInfo = useSelector((state) => state.session.sessionInfo);

  useEffect(() => {
    const sessionId = params.get("sessionId");
    const notes = window.localStorage.getItem(SOAP_NOTES);
    const soapNotes: Array<any> = notes ? JSON.parse(notes) : [];
    const savedData = soapNotes.find((x) => x.sessionId === sessionId);

    if (!sessionId) return;
    if (!hasAnyData(data) && !!savedData) {
      setData({
        type: ACTIONS.setState,
        payload: savedData,
      });
      return;
    }

    const { date, startTime, endTime, user, client } = sessionInfo;
    const allData = {
      ...data,
      sessionId,
      date,
      startTime,
      endTime,
      printedName: user.fullName.toUpperCase(),
      submit: false,
      client,
    };
    SaveSoapNote(allData);
  }, [data, params, sessionInfo]);

  const createSoapNoteHandler = (submit: boolean) => {
    const sessionId = params.get("sessionId");
    if (!sessionId || !data) return;
    const { date, startTime, endTime, user, client } = sessionInfo;
    const allData: SaveBehaviorTreatmentModificationTypeProperties = {
      ...data,
      date,
      startTime,
      endTime,
      printedName: user.fullName.toUpperCase(),
      submit,
      sessionId,
    };
    dispatch(
      saveBehaviorTreatmentModification({
        clientId: client.id,
        data: allData,
      })
    )
      .then(unwrapResult)
      .then(() => {
        resetData();
        removeSavedSoapNoteData(sessionId);
        toast("Success");
        navigate("/calendar");
      })
      .catch(errorNormalizer);
  };

  return (
    <>
      <BehaviorTreatmentModificationData data={data} setData={setData} />
      <SoapNoteButtons
        status={soapNoteStatus}
        save={() => createSoapNoteHandler(false)}
        submit={() => createSoapNoteHandler(true)}
      />
    </>
  );
};

export default CreateSoapNote;
