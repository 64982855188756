import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material";
import dayjs from "dayjs";

import {
  CustomButton,
  ClientStatusBadge,
  Text,
} from "../../../shared/uiComponents";
import { AdminTypes } from "../../../components/Menu";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { clearActiveAuthorization } from "../../../redux/State/clientSlice/insuranceAuthorizationSlice";
import {
  clearClients,
  clearTherapists,
} from "../../../redux/State/clientSlice/clientSlice";

const Wrapper = styled("div")(() => ({
  maxWidth: "360px",
  paddingRight: "16px",
  paddingBottom: "16px",
}));

const ClientInfo = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const {
    id,
    fullName,
    dateOfBirthday,
    address,
    insurance,
    insuranceNumber,
    status,
  } = useSelector((state) => state.client.client);
  const role = useSelector((state) => state.account.role);

  const navigate = useNavigate();

  const navigateAssignTherapistHandler = () => {
    if (!id) return;
    navigate(`/clients/client/assign/${id}`);
    dispatch(clearActiveAuthorization());
    dispatch(clearTherapists());
    dispatch(clearClients());
  };

  const navigateAllocateHours = () => {
    dispatch(clearActiveAuthorization());
    navigate(`/clients/client/allocate-authorization/${id}`);
  };

  return (
    <Wrapper>
      <Text title={fullName} size="largeBold" className="marginBottom8" />
      <Text
        title={`Date of birth: ${dayjs(dateOfBirthday).format("MM-DD-YYYY")}`}
        className="marginBottom8"
      />
      <Text title={`Address: ${address}`} className="marginBottom8" />
      {insurance && (
        <Text
          title={`Insurance: ${insurance.name}`}
          className="marginBottom8"
        />
      )}
      {insuranceNumber && (
        <Text
          title={`Insurance: ${insuranceNumber}`}
          className="marginBottom8"
        />
      )}
      <div className="marginLeft16 marginBottom16">
        <ClientStatusBadge statusCode={status.id} />
      </div>
      {role?.section.id === AdminTypes.admin && (
        <>
          <CustomButton
            title="Assign Provider"
            onClick={navigateAssignTherapistHandler}
            className="marginTop16"
            disabled={!id}
          />
          <CustomButton
            title="Allocate hours"
            secondaryButton
            onClick={navigateAllocateHours}
            className="marginTop16"
            disabled={!id}
          />
        </>
      )}
    </Wrapper>
  );
};

export default ClientInfo;
