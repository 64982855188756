import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { SelectChangeEvent } from "@mui/material";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";

import {
  CustomAlert,
  CustomButton,
  CustomSelect,
} from "../../../shared/uiComponents";
import { errorNormalizer } from "../../../shared/Helpers/functions";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  getDefaultBCBA,
  getDefaultBCBAOptions,
  updateDefaultBCBA,
} from "../../../redux/State/clientSlice/userClientSlice";
import { UpdateDefaultBCBAProperties } from "../../../redux/API/ClientAPIHelpers/userClientProperties";

const DefaultBCBA = () => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <CustomButton
        title="Set Default BCBA"
        onClick={() => setOpen(true)}
        className="marginTop8 marginBottom8"
      />
      <CustomAlert
        open={open}
        title="Update default BCBA"
        onClose={() => setOpen(false)}
        Content={() => <DefaultBCBAContent />}
      />
    </>
  );
};

const DefaultBCBAContent = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();
  const [userId, setUserId] = useState<string>("");

  const error = useSelector((state) => state.userClient.error);
  const defaultBCBA = useSelector((state) => state.userClient.defaultBCBA);
  const defaultBCBAoptions = useSelector(
    (state) => state.userClient.defaultBCBAOptions
  );

  const updateDefaultBCBAHandler = () => {
    if (!userId || !clientId) return;
    const data: UpdateDefaultBCBAProperties = {
      clientId,
      data: { userId },
    };
    dispatch(updateDefaultBCBA(data))
      .then(unwrapResult)
      .then(() => {
        toast("Success");
      })
      .catch(errorNormalizer);
  };

  const selectDefaultBCBAHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    if (!value) return;
    setUserId(value);
  };

  useEffect(() => {
    if (!defaultBCBA) return;
    setUserId(defaultBCBA.id);
  }, [defaultBCBA]);

  useEffect(() => {
    if (!clientId) return;
    dispatch(getDefaultBCBA(clientId));
    dispatch(getDefaultBCBAOptions(clientId));
  }, [clientId, dispatch]);

  return (
    <div>
      <CustomSelect
        value={userId}
        setValue={selectDefaultBCBAHandler}
        data={defaultBCBAoptions.map((x) => ({ id: x.id, label: x.fullName }))}
        error={!!error.title}
        errorMessage={error.title}
        className="marginBottom16"
      />
      <CustomButton
        title="Save"
        onClick={updateDefaultBCBAHandler}
        disabled={defaultBCBA?.id === userId}
      />
    </div>
  );
};

export default DefaultBCBA;
