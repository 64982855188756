import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { styled } from "@mui/material";
import dayjs from "dayjs";

import { Text, CustomAlert } from "../../../shared/uiComponents";
import { blue400, white100 } from "../../../shared/Helpers/colors";
import { Info } from "../../SOAPNotes/User/tableInfo";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { getClientSoapNotes } from "../../../redux/State/clientSlice/soapNoteSlice";
import { SoapNotesFullProperties } from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";

const NoteWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "8px",
  ">div:first-of-type": {
    width: "200px",
  },
  "@media (max-width: 1024px)": {
    flexDirection: "column",
    marginBottom: "16px",
    backgroundColor: white100,
    padding: "8px",
    borderRadius: "12px",
  },
}));

const NoteComponent = ({ note }: { note: SoapNotesFullProperties }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { status, createdAt } = note;
  return (
    <NoteWrapper>
      <Text
        title={`${dayjs(createdAt).format("MM/DD/YYYY")} - ${status.name}`}
        className={"overflowEllipsis"}
      />
      <Text
        title={"more"}
        size="tinyBold"
        textColor={blue400}
        onClick={() => setOpen(true)}
      />
      <CustomAlert
        open={open}
        onClose={() => setOpen(false)}
        title={"More Information"}
        Content={() => <Info note={note} />}
      />
    </NoteWrapper>
  );
};

const Notes = () => {
  const { clientId } = useParams();
  const [params] = useSearchParams();

  const dispatch = useDispatch<DispatchProperties>();
  const soapNotes = useSelector((state) => state.soapNote.clientSoapNotes);

  useEffect(() => {
    if (!clientId) return;
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "3";

    dispatch(getClientSoapNotes({ clientId, page, pageSize }));
  }, [params, dispatch, clientId]);

  return (
    <div style={{ maxWidth: "360px" }}>
      <Text
        title="Recent SOAP Notes:"
        size="tinyBold"
        className="marginTop8 marginBottom8"
      />
      <div
        style={{
          width: "100%",
          marginTop: "16px",
        }}
      >
        {!!soapNotes &&
          !!soapNotes.query &&
          soapNotes.query.map((note, index) => (
            <NoteComponent note={note} key={index} />
          ))}
      </div>
    </div>
  );
};

export default Notes;
