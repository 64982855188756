import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

import { CustomButton } from "../../../shared/uiComponents";
import SearchSelect from "../../../shared/uiComponents/Dropdown/searchSelect";
import { SelectDataProperties } from "../../../shared/uiComponents/Dropdown/searchSelect";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { useCheckPermission } from "../../../shared/Helpers/hooks";
import { PERMISSIONS } from "../../../App/constants";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { clearEvents } from "../../../redux/State/clientSlice/calendarSlice";
import { getUsers } from "../../../redux/State/userSlice";
import { getClients } from "../../../redux/State/clientSlice/clientSlice";
import { getUserClients } from "../../../redux/State/clientSlice/userClientSlice";

const AdminTools = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params, setParams] = useSearchParams();
  const USER = useCheckPermission(PERMISSIONS.USER.READ);
  const CLIENT = useCheckPermission(PERMISSIONS.CLIENT.READ);

  const loadingClientList = useSelector((state) => state.client.loading);
  const loadingUserList = useSelector((state) => state.user.loading);

  const [users, setUsers] = useState<Array<SelectDataProperties>>([]);
  const [clients, setClients] = useState<Array<SelectDataProperties>>([]);
  const [pageSize, setPageSize] = useState<number>(8);
  const [pageSizeClient, setPageSizeClient] = useState<number>(8);
  const [searchString, setSearchString] = useState<string>("");
  const [searchStringClient, setSearchStringClient] = useState<string>("");

  const onTherapistChoose = (value: string) => {
    params.set("userId", value);
    params.delete("clientId");
    setParams(params);
    dispatch(clearEvents());
  };

  const onClientChoose = (value: string) => {
    params.set("clientId", value);
    setParams(params);
    dispatch(clearEvents());
  };

  const displayDefaultViewHandler = useCallback(() => {
    params.delete("userId");
    params.delete("clientId");
    setParams(params);
    setSearchString("");
    setSearchStringClient("");
    dispatch(clearEvents());
  }, [params, setParams, dispatch]);

  useEffect(() => {
    if (!USER.permissionGranted) return;
    const timeout = setTimeout(() => {
      dispatch(getUsers({ page: "1", pageSize: `${pageSize}`, searchString }))
        .then(unwrapResult)
        .then((response) => {
          if (!response.query) return;
          const userData: Array<SelectDataProperties> = response.query.map(
            (x) => ({
              id: x.id,
              label: `${x.fullName} (${x.role.section.name})`,
            })
          );
          setUsers(userData);
        })
        .catch(errorNormalizer);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [pageSize, searchString, dispatch, USER.permissionGranted]);

  useEffect(() => {
    if (!CLIENT.permissionGranted) return;
    const timeout = setTimeout(() => {
      const userId = params.get("userId");
      if (!!userId) {
        dispatch(
          getUserClients({
            userId,
            page: "1",
            pageSize: `${pageSizeClient}`,
            searchString: searchStringClient,
            insuranceIds: [],
          })
        )
          .then(unwrapResult)
          .then((response) => {
            if (!response.query) return;
            const data: Array<SelectDataProperties> = response.query.map(
              (x) => ({
                id: x.id,
                label: x.fullName,
              })
            );
            setClients(data);
          })
          .catch(errorNormalizer);
      }

      dispatch(
        getClients({
          page: "1",
          pageSize: `${pageSizeClient}`,
          searchString: searchStringClient,
        })
      )
        .then(unwrapResult)
        .then((response) => {
          if (!response.query) return;
          const data: Array<SelectDataProperties> = response.query.map((x) => ({
            id: x.id,
            label: x.fullName,
          }));
          setClients(data);
        })
        .catch(errorNormalizer);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [
    searchStringClient,
    dispatch,
    pageSizeClient,
    params,
    CLIENT.permissionGranted,
  ]);

  return (
    <div style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
      {USER.permissionGranted && (
        <div style={{ width: "250px" }}>
          <SearchSelect
            data={users}
            label="Find Provider:"
            setSearchString={setSearchString}
            searchString={searchString}
            loading={loadingUserList}
            setValue={onTherapistChoose}
            loadMore={{
              activate: true,
              setSize: setPageSize,
            }}
          />
        </div>
      )}
      {CLIENT.permissionGranted && (
        <div style={{ width: "250px" }}>
          <SearchSelect
            data={clients}
            label="Find Client:"
            setSearchString={setSearchStringClient}
            searchString={searchStringClient}
            loading={loadingClientList}
            setValue={onClientChoose}
            loadMore={{
              activate: true,
              setSize: setPageSizeClient,
            }}
          />
        </div>
      )}
      <CustomButton
        title="Reset view"
        className="marginTop24 marginBottom8"
        secondaryButton
        onClick={displayDefaultViewHandler}
      />
    </div>
  );
};
export default AdminTools;
