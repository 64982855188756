import { ChangeEvent } from "react";
import { useSearchParams } from "react-router-dom";
import { CustomCheckBox } from "../../../shared/uiComponents";
import { useSelector } from "../../../redux/store";
import { SoapNotesStatuses } from "../../../redux/API/ClientAPIHelpers/dataCollectionProperties";
import { AdminTypes } from "../../../components/Menu";

const CalendarFilters = () => {
  const [params, setParams] = useSearchParams();
  const includeBTEvents =
    params.get("includeBTEvents") === "true" ? true : false;

  const userId = useSelector((state) => state.account.user.id);
  const role = useSelector((state) => state.account.role);
  const events = useSelector((state) => state.calendar.events);

  const userSubmittedSessions = events?.filter(
    (x) =>
      x.isCompleted &&
      !!x.reportStatus &&
      x.reportStatus.status === SoapNotesStatuses.submitted &&
      x.user.id === userId
  ).length;

  const userBTSubmittedSessions = events?.filter(
    (x) =>
      x.isCompleted &&
      !!x.reportStatus &&
      x.reportStatus.status === SoapNotesStatuses.submitted &&
      x.user.id !== userId
  ).length;

  const userBTSessions = events?.filter((x) => x.user.id !== userId).length;

  const drafts = events?.filter(
    (x) => !!x.reportStatus && x.reportStatus.status === SoapNotesStatuses.draft
  ).length;

  const labelCompleted = `Show Completed Sessions (${
    !!userSubmittedSessions
      ? includeBTEvents
        ? `${userSubmittedSessions} - ${userBTSubmittedSessions}`
        : userSubmittedSessions
      : 0
  })`;

  const labelBT = `Show BT Sessions ${
    !!userBTSessions ? `(${userBTSessions})` : ""
  }`;

  const labelDrafts = `Show Drafts ${!!drafts ? `(${drafts})` : ""}`;
  const showCompletedSessionHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    params.set("showCompleted", `${checked}`);
    setParams(params);
  };

  const showDraftsOnlyHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    params.set("showDraftsOnly", `${checked}`);
    setParams(params);
  };

  const showBTSessionsHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    params.set("includeBTEvents", `${checked}`);
    setParams(params);
  };

  return (
    <div>
      <CustomCheckBox
        item={{
          checked: params.get("showDraftsOnly") === "true",
          id: "1",
          label: labelDrafts,
        }}
        onChange={showDraftsOnlyHandler}
      />
      <CustomCheckBox
        item={{
          checked: params.get("showCompleted") === "true",
          id: "0",
          label: labelCompleted,
        }}
        onChange={showCompletedSessionHandler}
      />
      {!params.get("userId") &&
        !params.get("clientId") &&
        !!role &&
        role.section.id !== AdminTypes.bt && (
          <CustomCheckBox
            item={{
              checked: params.get("includeBTEvents") === "true",
              id: "1",
              label: labelBT,
            }}
            onChange={showBTSessionsHandler}
          />
        )}
    </div>
  );
};

export default CalendarFilters;
