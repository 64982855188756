import { FC } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  PersonOutlined as Person,
  EmailOutlined as Email,
  PhoneIphoneOutlined as Phone,
  AdminPanelSettingsOutlined as Admin,
  PermContactCalendarOutlined as Auth,
} from "@mui/icons-material";

import {
  Text,
  MobilePagination,
  UserStatusBadge,
} from "../../shared/uiComponents";
import { Loader, Row, Wrapper } from "../../components/StyledComponents";
import MobileSort from "../../components/MobileSort";
import UserAction from "../../components/UserAction";
import { sortData } from "../Users/mobileView";
import { AdminDataProperties } from "./tableInfo";

import { DispatchProperties, useSelector } from "../../redux/store";
import { getBCBAs } from "../../redux/State/userSlice";

const MobileView: FC<AdminDataProperties> = (data) => {
  const { query, totalNumberOfItems } = data;
  const [params] = useSearchParams();

  const dispatch = useDispatch<DispatchProperties>();
  const loading = useSelector((state) => state.user.loading);

  const fetchUsers = () => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";

    dispatch(getBCBAs({ page, pageSize, orderBy, direction, searchString }));
  };

  return (
    <div className={"mobileView"}>
      <MobileSort data={sortData} />
      {loading && <Loader />}
      {!loading && !totalNumberOfItems && (
        <div className={"mobileView"}>
          <Text
            title={"No results"}
            size={"smallBold"}
            className={"marginAuto"}
          />
        </div>
      )}
      {!loading &&
        !!query &&
        query.map((item, index) => (
          <Wrapper key={index}>
            <Row style={{ justifyContent: "space-between" }}>
              <div className="paddingLeft16">
                <UserStatusBadge emailConfirmed={item.emailConfirmed} />
              </div>
              <UserAction item={item} fetchUsers={fetchUsers} />
            </Row>
            <Row>
              <Person />
              <Text title={item.fullName} className={"marginLeft8"} />
            </Row>
            <Row>
              <Email /> <Text title={item.email} className={"marginLeft8"} />
            </Row>
            <Row>
              <Phone />
              <Text
                title={!!item.phoneNumber ? item.phoneNumber : "-"}
                className={"marginLeft8"}
              />
            </Row>
            <Row>
              <Auth />
              <Text
                title={
                  !!item.authorizationType ? item.authorizationType.name : "-"
                }
                className={"marginLeft8"}
              />
            </Row>
            <Row>
              <Admin />
              <Text title={item.role.section.name} className={"marginLeft8"} />
            </Row>
          </Wrapper>
        ))}
      <MobilePagination data={data} />
    </div>
  );
};

export default MobileView;
