import { useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { errorNormalizer } from "../../../../shared/Helpers/functions";
import {
  CustomAlert,
  CustomButton,
  Text,
} from "../../../../shared/uiComponents";
import { AdminTypes } from "../../../../components/Menu";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import { deleteEvent, deleteUserEvent } from "../../../../redux/State/clientSlice/calendarSlice";
import { SoapNotesStatuses } from "../../../../redux/API/ClientAPIHelpers/dataCollectionProperties";

const DeleteSession = ({ resetData }: { resetData: () => void }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const [areYouSure, setAreYouSure] = useState<boolean>(false);
  const data = useSelector((state) => state.calendar.infoEventData);
  const role = useSelector((state) => state.account.role);

  const onDeleteHandler = () => {
    const clientId = params.get('clientId');
    const userId = params.get('userId');

    if (!clientId && !userId) {
      deleteEventBCBAHandler();
      return;
    }

    deleteEventAdminHandler();
  };

  const deleteEventBCBAHandler = () => {
    if (!data || !data.id) return;
    dispatch(deleteEvent(data.id))
      .then(unwrapResult)
      .then(() => {
        toast("Deleted");
        resetData();
      })
      .catch(errorNormalizer);
  }

  const deleteEventAdminHandler = () => {
    if (!data || !data.id || !data.user.id) return;
    const userId = data.user.id;
    const eventId = data.id;

    dispatch(deleteUserEvent({ userId, eventId }))
      .then(unwrapResult)
      .then(() => {
        toast("Deleted");
        resetData();
      })
      .catch(errorNormalizer);
  }

  return (
    <>
      {areYouSure ? (
        <div style={{ display: "flex" }}>
          <CustomButton
            title="Are you sure?"
            onClick={onDeleteHandler}
            className={"marginRight8"}
          />
          <CustomButton
            title="Cancel"
            onClick={() => setAreYouSure(false)}
            secondaryButton
          />
        </div>
      ) : (
        <CustomButton
          title="Delete calendar event"
          onClick={() => setAreYouSure(true)}
          secondaryButton
        />
      )}
      <CustomAlert
        open={
          !!role && role.section.id === AdminTypes.admin && areYouSure
        }
        onClose={() => setAreYouSure(false)}
        title="Are you sure?"
        Content={() => (
          <div>
            {!!data && !!data.sessionId && (
              <>
                <Text title={"Attention"} size="largeBold" />
                <Text
                  title={"Session data will be deleted."}
                  size="mediumBold"
                />
              </>
            )}
            {!!data?.reportId &&
              SoapNotesStatuses.draft !== data?.reportStatus?.status && (
                <Text title={"Soap note will be deleted."} size="mediumBold" />
              )}
            <div style={{ display: "flex" }} className="marginTop16">
              <CustomButton
                title="Delete"
                onClick={onDeleteHandler}
                className={"marginRight8"}
              />
              <CustomButton
                title="Cancel"
                onClick={() => setAreYouSure(false)}
                secondaryButton
              />
            </div>
          </div>
        )}
      />
    </>
  );
};

export default DeleteSession;
