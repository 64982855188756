import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  CustomAccordion,
  CustomButton,
  CustomTabs,
  Text,
} from "../../../shared/uiComponents";
import { useScreenWidth } from "../../../shared/Helpers/hooks";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { red300 } from "../../../shared/Helpers/colors";
import { Container } from "../../../components/StyledComponents";
import CredentialedBCBA from "./credentialedBCBA";
import NotCredentialedBCBA from "./notCredentialedBCBA";
import BT from "./BT";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  clearTherapists,
  getClient,
} from "../../../redux/State/clientSlice/clientSlice";
import {
  assignUsersToClient,
  getTherapists,
} from "../../../redux/State/clientSlice/userClientSlice";

const tabHeaders = [`BCBA - credentialed`, `BCBA - not credentialed`, `BT`];
const panels = [CredentialedBCBA, NotCredentialedBCBA, BT];

const Assign = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const screenWidth = useScreenWidth();
  const { clientId } = useParams();
  const navigate = useNavigate();

  const selectedUsers = useSelector(
    (state) => state.client.therapistsListForAssign
  );
  const billingUserId = useSelector((state) => state.userClient.billableUserId);
  const therapists = useSelector((state) => state.userClient.therapists);
  const loading = useSelector((state) => state.userClient.loading);
  const clientInfo = useSelector((state) => state.client.client);

  const assignedUsers = selectedUsers.filter((x) => x.isAssigned);
  const supervisorsUpdated = assignedUsers.filter((x) =>
    therapists?.find((z) => z.id === x.id)
  );
  const unAssignedUsers = selectedUsers.filter(
    (x) => !x.isAssigned && therapists?.find((y) => y.id === x.id)
  );

  const userIds = [...assignedUsers, ...unAssignedUsers];

  const assginTherapistsHandler = () => {
    if (!clientId) return;

    dispatch(
      assignUsersToClient({
        userIds,
        clientId,
        billingUserId,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        if (!response.isSuccessful) throw new Error("Failed");
        toast("Success");
        navigate(`/clients/client/allocate-authorization/${clientId}`);
      })
      .catch(errorNormalizer)
      .finally(() => {
        dispatch(clearTherapists());
      });
  };

  useEffect(() => {
    if (!!therapists || !clientId) return;
    dispatch(getTherapists(clientId));
  }, [clientId, therapists, dispatch]);

  useEffect(() => {
    if (!clientId) return;
    if (!!clientInfo.fullName) return;
    dispatch(getClient(clientId));
  }, [clientId, clientInfo, dispatch]);

  return (
    <Container>
      <Text
        title={`Assign Providers for ${clientInfo.fullName}`}
        size="largeBold"
        className="paddingBottom16"
      />
      {screenWidth > 768 ? (
        <CustomTabs headers={tabHeaders} panels={panels} />
      ) : (
        tabHeaders.map((header, index) => (
          <CustomAccordion
            key={index}
            HeaderContent={() => <Text title={header} size="smallBold" />}
            Content={panels[index]}
          />
        ))
      )}
      <div style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
        <div style={{ width: "400px" }} className="padding16">
          <Text
            title={`Assigned Providers (${assignedUsers.length}):`}
            size="mediumBold"
          />
          {assignedUsers
            .filter((x) => !therapists?.find((z) => z.id === x.id))
            .map((y) => (
              <Text title={y.fullName} />
            ))}
          <Text title={`Supervisor updated:`} size="tinyBold" />
          {supervisorsUpdated.map((y) => (
            <Text title={y.fullName} size="tiny" />
          ))}
          <Text
            title={`Unassigned Providers (${unAssignedUsers.length}): `}
            size="mediumBold"
          />
          {unAssignedUsers.map((y) => (
            <Text title={y.fullName} />
          ))}
        </div>
        <div style={{ width: "350px", textAlign: "center" }}>
          <Text
            title={
              "Changes from all tabs will be saved. Adjust provider selections as needed before saving."
            }
            textColor={red300}
            size="tinyBold"
            className="padding8"
          />
          <CustomButton
            title="Save"
            className={"marginTop16"}
            onClick={assginTherapistsHandler}
            loading={loading}
          />
        </div>
      </div>
    </Container>
  );
};

export default Assign;
