import { Navigate, Outlet } from "react-router-dom";

import EditSoapNote from "../pages/SOAPNotes/Admin/editSoapNote";
import SOAPNote from "../pages/SOAPNote";
import Session from "../pages/Calendar/DataCollection";
import Client from "../pages/Clients/Client";
import User from "../pages/Users";
import Admin from "../pages/Admins";
import SOAPNotes from "../pages/SOAPNotes";
import Calendar from "../pages/Calendar";
import Dashboard from "../components/Dashboard";
import MBHReport from "../pages/Reports/BillingReports/MBHReport";
import NonCredentialedReport from "../pages/Reports/BillingReports/NonCredentialedReport";
import GraphReports from "../pages/Reports/GraphReports";
import DownloadSoapNotes from "../pages/Reports/SoapNotes";
import UtilizationReport from "../pages/Reports/UtilizationReport";
import PendingDSU from "../pages/Reports/PendingDSU";
import ExpiredSessions from "../pages/Reports/ExpiredSessions";
import BCBA from "../pages/BCBA";
import BT from "../pages/BT";
import AddAdmin from "../pages/UserForm/addAdmin";
import UpdateAdmin from "../pages/UserForm/updateAdmin";
import Clients from "../pages/Clients";
import AddClient from "../pages/Clients/ClientForm/addClient";
import UpdateClient from "../pages/Clients/ClientForm/updateClient";
import Assign from "../pages/Clients/AssignTherapists";
import InsuranceAuthorization from "../pages/Clients/Client/InsuranceAuthorization/AllocateInsurance";
import Targets from "../pages/Clients/Client/SkillAcquisition/Targets";
import CreateTarget from "../pages/Clients/Client/SkillAcquisition/Targets/Actions/CreateTarget";
import Permissions from "../pages/Permissions";
import AddPermission from "../pages/Permissions/addPermission";
import UpdatePermission from "../pages/Permissions/updatePermission";
import NotFound from "./notFound";

import API from "../redux/API/Main/API";
import { getToken } from "../redux/API/Main/helpers";

export const Components = [
  //all users
  { path: "dashboard", Component: Dashboard },
  { path: "calendar", Component: Calendar },
  { path: "calendar/session/:sessionId/:clientId", Component: Session },
  { path: "clients", Component: Clients },
  { path: "clients/client/:clientId", Component: Client },
  {
    path: "clients/client/:clientId/:programId",
    Component: Targets,
  },
  { path: "soap-notes", Component: SOAPNotes },
  { path: "soap-notes/info", Component: SOAPNote },
  //admin & BCBA
  {
    path: "clients/client/target/:programId",
    Component: CreateTarget,
  },
  //admin
  { path: "user", Component: User },
  { path: "admin", Component: Admin },
  { path: "bcba", Component: BCBA },
  { path: "bt", Component: BT },
  { path: "user/new", Component: AddAdmin },
  { path: "admin/new", Component: AddAdmin },
  { path: "bcba/new", Component: AddAdmin },
  { path: "bt/new", Component: AddAdmin },
  { path: "user/:userId", Component: UpdateAdmin },
  { path: "admin/:userId", Component: UpdateAdmin },
  { path: "bcba/:userId", Component: UpdateAdmin },
  { path: "bt/:userId", Component: UpdateAdmin },
  { path: "clients/new", Component: AddClient },
  { path: "clients/:clientId", Component: UpdateClient },
  { path: "clients/client/assign/:clientId", Component: Assign },
  {
    path: "clients/client/allocate-authorization/:clientId",
    Component: InsuranceAuthorization,
  },
  { path: "permissions", Component: Permissions },
  { path: "permissions/new", Component: AddPermission },
  { path: "permissions/:roleId", Component: UpdatePermission },
  { path: "soap-notes/edit", Component: EditSoapNote },
  { path: "*", Component: NotFound },
];

export const ReportComponents = [
  { path: "mbh", Component: MBHReport },
  { path: "nc", Component: NonCredentialedReport },
  { path: "graph", Component: GraphReports },
  { path: "soap", Component: DownloadSoapNotes },
  { path: "utilization", Component: UtilizationReport },
  { path: "pending-dsu", Component: PendingDSU },
  { path: "expired-sessions", Component: ExpiredSessions },
];

export const ProtectedRoutes = () => {
  const token = getToken();

  if (!token) {
    return <Navigate to="/login" />;
  }
  const parsedToken = JSON.parse(token);
  API.setToken(parsedToken.access_token);

  return <Outlet />;
};

export const LoginChecker = () => {
  const token = getToken();

  if (!!token) {
    return <Navigate to="/dashboard" />;
  }

  return <Outlet />;
};
