import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material/Select";

import { AdminTypes } from "../../../components/Menu";
import { CustomSelect } from "../../../shared/uiComponents";
import { useScreenWidth } from "../../../shared/Helpers/hooks";
import StartSession from "./startSession";
import CalendarFilters from "./filters";
import AdminTools from "./adminTools";
import Navigation from "./navigation";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { setCalendarView } from "../../../redux/State/clientSlice/calendarSlice";

const CalendarHeader = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const screenWidth = useScreenWidth();
  const [params] = useSearchParams();

  const role = useSelector((state) => state.account.role);
  const viewer = useSelector((state) => state.calendar.calendarView);

  const viewHandler = (event: SelectChangeEvent<string>) => {
    const value = parseInt(event.target.value);
    dispatch(setCalendarView(value));
  };

  return (
    <>
      <div className="calendarHeader">
        <Navigation />
        <CalendarFilters />
        {screenWidth > 425 && (
          <CustomSelect
            data={[
              { id: "0", name: "month" },
              { id: "1", name: "week" },
            ]}
            value={`${viewer}`}
            setValue={viewHandler}
            className={"marginRight16"}
          />
        )}
        {!params.get("userId") && !params.get("clientId") && <StartSession />}
      </div>
      {!!role && role.section.id === AdminTypes.admin && <AdminTools />}
    </>
  );
};

export default CalendarHeader;
