import { FC, useCallback, useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { TableBody as Body, TableCell, TableRow } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import {
  Text,
  CustomAccordion,
  TableNoData,
  UserStatusBadge,
} from "../../shared/uiComponents";
import {
  HeaderProperties,
  TableDataProperties,
} from "../../shared/uiComponents/Table/tableProperties";
import { errorNormalizer } from "../../shared/Helpers/functions";
import { Loader, InfoRow as Row } from "../../components/StyledComponents";
import Delete from "../../components/Delete";
import UserAction from "../../components/UserAction";

import { UserProperties } from "../../redux/API/userAPIProperties";
import { DispatchProperties, useSelector } from "../../redux/store";
import { getBTs } from "../../redux/State/userSlice";
import {
  dismissClient,
  getUserClients,
} from "../../redux/State/clientSlice/userClientSlice";
import { DismissClientProperties } from "../../redux/API/ClientAPIHelpers/userClientProperties";

export interface AdminDataProperties extends TableDataProperties {
  query: Array<UserProperties> | null;
}

interface RowRendererProperties {
  data: UserProperties[];
}

export const Headers: HeaderProperties[] = [
  { id: "0", name: "Full Name", orderBy: "fullName" },
  {
    id: "1",
    name: "Number of Clients",
    orderBy: "numberOfClients",
    width: "200px",
  },
  { id: "2", name: "Status", orderBy: "emailConfirmed" },
  { id: "4", name: "Role" },
  { id: "5", name: "Actions", width: "120px" },
];

export const UserInfo = (info: UserProperties) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const [clientsPageSize, setClientsPageSize] = useState<number>(8);

  const clients = useSelector((state) => state.userClient.userClients);
  const loading = useSelector(
    (state) => state.userClient.loadingGetUserClients
  );

  const fetchUsers = () => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";

    dispatch(getBTs({ page, pageSize, orderBy, direction, searchString }));
  };

  const fetchUserClients = useCallback(() => {
    const page = "1";
    const userId = info.id;
    if (!userId) return;
    dispatch(
      getUserClients({ userId, page, pageSize: clientsPageSize.toString() })
    );
  }, [info, clientsPageSize, dispatch]);

  const dismissHandler = ({ clientId, userId }: DismissClientProperties) => {
    dispatch(dismissClient({ clientId, userId }))
      .then(unwrapResult)
      .then(() => {
        toast("Removed");
        fetchUserClients();
        fetchUsers();
      })
      .catch(errorNormalizer);
  };

  useEffect(() => {
    fetchUserClients();
  }, [fetchUserClients]);

  return (
    <>
      <Row>
        <Text title={"First Name: "} size={"smallBold"} />
        <Text title={info.firstName} />
      </Row>
      <Row>
        <Text title={"Last Name: "} size={"smallBold"} />
        <Text title={info.lastName} />
      </Row>
      <Row>
        <Text title={"Email: "} size={"smallBold"} />
        <Text title={info.email} />
      </Row>
      <Row>
        <Text title={"Status: "} size={"smallBold"} />
        <div className="paddingLeft16">
          <UserStatusBadge emailConfirmed={info.emailConfirmed} />
        </div>
      </Row>
      <Row>
        <Text title={"Number of Clients: "} size={"smallBold"} />
        <Text title={`${info.numberOfClients}`} />
      </Row>
      <Row>
        <Text title={"Phone Number: "} size={"smallBold"} />
        <Text title={info.phoneNumber} />
      </Row>
      <Row>
        <Text title={"Address: "} size={"smallBold"} />
        <Text title={info.address} />
      </Row>
      <CustomAccordion
        headerStyle={{ padding: 0 }}
        HeaderContent={() => (
          <Row>
            <Text title={"Clients Assigned: "} size={"smallBold"} />
            <Text title={`${info.numberOfClients}`} />
          </Row>
        )}
        Content={() => (
          <>
            {!!clients.query?.length && !loading ? (
              <>
                {clients.query?.map((client, index) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    key={index}
                  >
                    <Text
                      title={`${index + 1}. ${client.fullName}`}
                      className={"marginRight8"}
                    />
                    {!!client.id && !!info.id && (
                      <Delete
                        deleteHandler={() =>
                          dismissHandler({
                            clientId: client.id,
                            userId: info.id,
                          })
                        }
                      />
                    )}
                  </div>
                ))}
                {clients.hasNextPage && (
                  <div style={{ justifyContent: "center", display: "flex" }}>
                    <Text
                      title="Load More"
                      size="smallBold"
                      onClick={() => setClientsPageSize((prev) => prev + 8)}
                    />
                  </div>
                )}
              </>
            ) : (
              <Text title={"None"} />
            )}
            {loading && <Loader />}
          </>
        )}
      />
    </>
  );
};

export const TableBody: FC<RowRendererProperties> = ({ data }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const fetchUsers = () => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";

    dispatch(getBTs({ page, pageSize, orderBy, direction, searchString }));
  };

  if (!data || !data.length) {
    return <TableNoData spanScope={Headers.length} />;
  }

  return (
    <Body>
      {data.map((row, index) => (
        <TableRow key={index}>
          <TableCell>
            <Text title={row.fullName} size={"tiny"} />
          </TableCell>
          <TableCell>
            <Text title={row.numberOfClients.toString()} size={"tiny"} />
          </TableCell>
          <TableCell>
            <UserStatusBadge emailConfirmed={row.emailConfirmed} />
          </TableCell>
          <TableCell>
            <Text title={row.role.name} size={"tiny"} />
          </TableCell>
          <TableCell>
            <UserAction item={row} fetchUsers={fetchUsers} />
          </TableCell>
        </TableRow>
      ))}
    </Body>
  );
};
