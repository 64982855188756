import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ChangeEvent, useEffect, useState } from "react";
import { SelectChangeEvent, styled } from "@mui/material";

import {
  CustomButton,
  CustomTable,
  Text,
  CustomSelect,
  CustomRadio,
} from "../../../../../shared/uiComponents";
import { SelectDataProperties } from "../../../../../shared/uiComponents/Dropdown";
import { AdminTypes } from "../../../../../components/Menu";
import { TableBody, headers } from "../tableInfo";
import Allocate from "./allocate";

import { DispatchProperties, useSelector } from "../../../../../redux/store";
import { getTherapists } from "../../../../../redux/State/clientSlice/userClientSlice";
import { getActiveInsuranceAuthorizations } from "../../../../../redux/State/clientSlice/insuranceAuthorizationSlice";
import { ActiveAuthorizationProperties } from "../../../../../redux/API/ClientAPIHelpers/insuranceAuthorizationProperties";

const Wrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  width: "300px",
}));

const OPTIONS = [
  { id: "0", title: "For BCBA" },
  { id: "1", title: "For BT" },
];

const AllocateInsurance = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const [params, setParams] = useSearchParams();

  const therapists = useSelector((state) => state.userClient.therapists);
  const authorizationList = useSelector(
    (state) => state.authorization.authorizationDetails
  );

  const [therapistId, setTherapistId] = useState<string>("");
  const [option, setOption] = useState<string>("1");
  const [therapistsDataBCBA, setTherapistsDataBCBA] = useState<
    Array<SelectDataProperties>
  >([]);
  const [therapistsDataBT, setTherapistsDataBT] = useState<
    Array<SelectDataProperties>
  >([]);
  const [authorizations, setAuthorizations] = useState<
    Array<ActiveAuthorizationProperties>
  >([]);

  useEffect(() => {
    if (!authorizationList) return;
    if (option === "1") {
      const btAuthorizations = authorizationList
        .filter((x) =>
          x.authorizationDetails.find((y) => y.sessionType.isForRbts)
        )
        .map((z) => ({
          ...z,
          authorizationDetails: z.authorizationDetails.filter((t) =>
            t.sessionType.name.includes("Behavior Treatment")
          ),
        }));
      setAuthorizations(btAuthorizations);
      return;
    }
    const bcbaAuthorizations = authorizationList
      .filter((x) =>
        x.authorizationDetails.find((y) => !y.sessionType.isForRbts)
      )
      .map((z) => ({
        ...z,
        authorizationDetails: z.authorizationDetails.filter(
          (t) => !t.sessionType.name.includes("Behavior Treatment")
        ),
      }));
    setAuthorizations(bcbaAuthorizations);
  }, [option, authorizationList]);

  useEffect(() => {
    if (!therapists) return;
    const dataBCBA: Array<SelectDataProperties> = therapists
      .filter((x) => x.role.section.id !== AdminTypes.bt)
      .map((x) => ({
        id: x.id,
        name: `${x.fullName} (${x.role.name})`,
      }));
    const dataBT: Array<SelectDataProperties> = therapists
      .filter((x) => x.role.section.id === AdminTypes.bt)
      .map((x) => ({
        id: x.id,
        name: `${x.fullName}`,
      }));
    setTherapistsDataBCBA(dataBCBA);
    setTherapistsDataBT(dataBT);
  }, [therapists, dispatch]);

  useEffect(() => {
    if (!clientId) return;
    dispatch(getTherapists(clientId));
  }, [clientId, dispatch]);

  useEffect(() => {
    if (!clientId || !!authorizationList) return;
    dispatch(getActiveInsuranceAuthorizations({ clientId }));
  }, [clientId, authorizationList, dispatch]);

  const onChangeTherapist = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setTherapistId(value);
  };

  const navigateAddAuthorizationHandler = () => {
    window.localStorage.setItem("tab", "2");
    navigate(`/clients/client/${clientId}`);
  };

  const therapistRadioHandler = (
    _: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setOption(value);
    params.delete("authId");
    setParams(params);
    setTherapistId("");
  };

  return !!authorizationList && !!authorizationList.length ? (
    <>
      <Wrapper className="marginBottom16 marginTop16">
        {OPTIONS.map((x) => (
          <CustomRadio
            id={x.id}
            label={x.title}
            value={option}
            onChange={therapistRadioHandler}
          />
        ))}
        <CustomSelect
          data={option === "0" ? therapistsDataBCBA : therapistsDataBT}
          value={therapistId}
          setValue={onChangeTherapist}
          className="marginBottom16 marginTop16"
        />
        <Allocate therapistId={therapistId} />
      </Wrapper>
      <CustomTable
        TableBody={TableBody}
        headers={headers}
        data={{ query: authorizations }}
        loading={false}
        hasPagination={false}
      />
    </>
  ) : (
    <Wrapper>
      <Text
        title={"Client does not have active insurances"}
        size="smallBold"
        className="marginBottom16"
      />
      <CustomButton
        title="Please, Add authorization"
        onClick={navigateAddAuthorizationHandler}
      />
    </Wrapper>
  );
};

export default AllocateInsurance;
