import { List, ListItem, ListItemButton } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import { Text } from "../../shared/uiComponents";
import { black900, white100 } from "../../shared/Helpers/colors";
import { useCheckPermission } from "../../shared/Helpers/hooks";
import { MenuProperties } from "../../App/constants";

const MenuElements = ({
  menu,
}: {
  menu: Array<MenuProperties>;
}) => {
  return (
    <List>
      <Text title={"Menu"} size="mediumBold" className="paddingLeft8 title" />
      {menu.map((item, index) => (
        <LinkItem key={index} item={item} />
      ))}
    </List>
  );
};

const LinkItem = ({ item }: { item: MenuProperties }) => {
  const { pathname } = useLocation();
  const { permissionGranted } = useCheckPermission(item.permission);
  if (!permissionGranted) return null;
  return (
    <Link className="menuLink" to={item.url}>
      <ListItem disablePadding>
        <ListItemButton selected={pathname.includes(item.url)}>
          <Text
            title={item.title}
            size="smallBold"
            textColor={pathname.includes(item.url) ? white100 : black900}
            onClick={() => {}}
          />
        </ListItemButton>
      </ListItem>
    </Link>
  );
};

export default MenuElements;
