import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { EditOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { errorNormalizer, validateEmail } from "../../shared/Helpers/functions";
import {
  CustomInput,
  CustomAlert,
  CustomButton,
} from "../../shared/uiComponents";
import { ButtonWrapper } from "../../components/StyledComponents";

import { DispatchProperties } from "../../redux/store";
import { requestEmailChange } from "../../redux/State/identitySlice/userSlice";

const ChangeEmail = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Tooltip title={"Change Email"}>
        <EditOutlined
          fontSize="small"
          className={"marginTop32"}
          onClick={() => setOpen(true)}
        />
      </Tooltip>
      <CustomAlert
        open={open}
        onClose={() => setOpen(false)}
        Content={({ onClose }) => <ChangeEmailContent onClose={onClose} />}
      />
    </>
  );
};

const ChangeEmailContent = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { userId } = useParams();
  const [newEmail, setNewEmail] = useState<string>("");
  const [error, setError] = useState<string>("");

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setNewEmail(value);
    setError("");
  };

  const onEmailBlurHandler = () => {
    if (!validateEmail(newEmail)) {
      setError("Invalid email");
      return;
    }
    setError("");
  };

  const startChangeEmailHandler = () => {
    if (!newEmail || !userId) return;
    dispatch(
      requestEmailChange({
        newEmail,
        userId,
      })
    )
      .then(unwrapResult)
      .then(() => {
        toast("Success");
        onClose();
      })
      .catch(errorNormalizer);
  };
  return (
    <div>
      <CustomInput
        label={"New Email"}
        value={newEmail}
        setValue={onEmailChange}
        className={"marginBottom16"}
        error={!!error}
        errorMessage={error}
        onBlur={onEmailBlurHandler}
      />
      <ButtonWrapper className={"marginTop16"}>
        <CustomButton
          title="Send confirmation"
          onClick={startChangeEmailHandler}
        />
        <CustomButton secondaryButton title="Cancel" onClick={onClose} />
      </ButtonWrapper>
    </div>
  );
};

export default ChangeEmail;
